
import React, { useState, useEffect, Component } from 'react';
import jwt_decode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { loginActions } from "../../core/actions";
import { patientActions } from '../../core/actions';
import {helperServices} from "../../data/index";
import { Row, Col, Form, FormInput, Button } from "shards-react";
import {LOGIN_REQUEST, SET_SIGNINDATA} from "../../shared/constants";



function Login(props) {
  
const [showForm, setShowForm] = useState(false);
  const [loginId, setLoginId] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [user, setUser] = useState({
    loginId: "",
    loginPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState('');
  const [responseCodeMsgHashMap, setResponseCodeMsgHashMap] = useState([]);
  const [sidebarNavItems, setSidebarNavItems] = useState(helperServices.getSideBarNavItems());
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [login, setlogin]= useState(true);  
  const [logout, setLogout]= useState(false);
  // const [url]=useState({'http://localhost:7081/api/auth/accounts/v1/signin'});
  const [error,setError] = useState(false);
  // const [url]=useState({'http://localhost:8081/api/auth/accounts/v1/signin'});
  // let url = "http://localhost:7081/api/auth/accounts/v1/googlelogin";
  let url = process.env.REACT_APP_ACCOUNTS_URL;
  const clientId = "280018234179-qnlgdnsrhjeg7fd5jlggardtbg6ccfil.apps.googleusercontent.com";
  // let loginurl = "http://localhost:7081/api/auth/login";      
  let loginurl = process.env.REACT_APP_ACCOUNTS_LOGINURL;
  
  var userData = user.loginId;

  const handleSubmit = (event) => {
    console.log("In handle submit of login page");
    let hidbtnid=document.getElementById("loginbutton")
   //  setSubmitted(true);
   //  Object.keys(user).forEach((key) => {
   //    validateField(key, user[key]);
   //  });

   //  validateForm(event);
  }

  const handlegoogleSubmit = (event) => {
    let googleform=document.getElementById("formid");
    console.log("documents:",googleform);
    googleform.submit();
  }

     function handleCallbackResponse(response) {
      console.log("Encoded jwt token: " + response.credential);
      var userobject = jwt_decode(response.credential);
      setToken (response.credential);
      setEmail (userobject.email);
     console.log("userobject :",userobject,"userobject.email :",userobject.email);
     console.log(document);
     handlegoogleSubmit(response);
     }

  useEffect(() => {
    /* global google */
    if(google.accounts){
      google.accounts.id.initialize({
        client_id:"465066940286-38oal5huee33jf7dlnolicdgj33jsllc.apps.googleusercontent.com",
        callback: handleCallbackResponse
      });
    
      google.accounts.id.renderButton(
        document.getElementById("google"),
          {theme:"outline", size:"large"}
       );
    }


   const url = window.location.href;
   console.log("url : ",url);
   let arr = [];
   if (url.includes("error")){
    arr = url.split('=');
    if(arr[1]==='0'){
      setError(true);
    }
   }
   console.log("arr : ",arr);
    // console.log("log ")
    // const script = document.createElement('script');
  

    // script.src = 'https://accounts.google.com/gsi/client';
    // script.async = true;
   
    // document.body.appendChild(script);
    // script.addEventListener ('load',() => {
    //   console.log('page is fully loaded');
      
    //   window.google.accounts.id.initialize({
    //     client_id: "280018234179-1dhmfru6nkiurcnobetbumgvgdjji8l3.apps.googleusercontent.com",
    //     callback: handleCredentialResponse()
    //   });
    //   window.google.accounts.id.renderButton(
    //     document.getElementById("mybutton"),
    //     { theme: "outline", size: "large" }  // customization attributes
    //   );
    //   window.google.accounts.id.prompt(); 
    // });

    
   
   
  }, []);
  

  // window.onload = function () 

    return (



      <div>
      {/* <img
        src="https://t4.ftcdn.net/jpg/04/25/07/29/240_F_425072919_xaZZugSVfTyGud3JRyDMNrv7NapLTkQH.jpg"
        alt="Shard"
        className="shard"
      ></img> */}
       <div className="welcome d-flex justify-content-center flex-column">
        <div className="inner-wrapper mt-auto mb-auto">
          <div className="slide-in visible">
            <div className="loginDiv">
              <div className="Row verticalCenter">
                <Col md="6" lg="6" sm="12" xs="12">
                  <div className="x_panel">
                    <div className="x_title d-flex justify-content-center align-items-center">
                      <div className='d-flex flex-column align-items-center'>
                      <img src = "Manaswini_Logo.jpg" className='manaswini-logo' ></img>
                      <h3>Login</h3>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="x_content">
                    {/* <form action={loginurl} method="get">
                      <input type="text" name="userName" />
                      <input type="text" name="password" />
                      <button type="submit">Submit</button>
                  </form> */}
                  
                       <Form id = "loginformid" action={loginurl} method= "get">
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="uname">User Name</label>
                            <br></br>
{/* className = "w-100  border: 1px solid border-radius: 4px  box-sizing: border-box" */}
                            <input className = {error ? "has-error w-80 x_content" :"w-80 x_content"} type="text" required name="userName"/>
                           

                            {/* <FormInput
                              value={user.loginId}
                              placeholder="User Name"
                              required
                              autoComplete="off"
                              // type='submit'
                              onChange={(event) => {
                                handleInputChange(
                                  "loginId",
                                  event.target.value
                                );
                              }}
                            /> */}
                            {/* <div style={{ color: "#EC4147" }}>
                              <span>{formErrors.username}</span>
                            </div> */}
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="password">Password</label>
                            <br></br>
                            <input className = {error ? "has-error w-80 x_content" :"w-80 x_content"} type="password" required name="password"/>
                            {/*<FormInput
                              type="password"
                              value={user.loginPassword}
                              placeholder="Password"
                              required
                              autoComplete="off"
                              // type='submit'
                              onChange={(event) => {
                                handleInputChange(
                                  "loginPassword",
                                  event.target.value
                                );
                              }}
                            /> */}
                           

                            {/* <div style={{ color: "#EC4147" }}>
                              <span>{formErrors.password}</span>
                            </div> */}
                          </Col>
                        </Row>
                        {error && (

                            <Row>
                              <Col>
                            <span id="error" className='error-input'>Please enter valid username and password</span>
                              
                              </Col>
                            </Row>
                        )

                        }
                        <div className="actionBar">
                           <Button id="loginbutton" type="submit" onClick={(event) => handleSubmit(event)}
                            > 
                             Login 
                           </Button>  
                        </div>
                        <h5> Welcome To Google Sign In
           </h5>
           {/* <button id="google"></button> */}
                      </Form> 
                      <div>
         
                      
        {/* <div id="g_id_onload" className="display: flex justify-content: center" data-callback= {googleloginsuccess()} data-client_id="465066940286-38oal5huee33jf7dlnolicdgj33jsllc.apps.googleusercontent.com"
          data-auto_prompt="true">
            </div>
       <div class="g_id_signin" id="mybutton"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
        >
    </div> */}

    


   <form className = "" id = "formid" method= "get" action={url}>
   
      <div id ="google"></div>
      <div>
      <input
        type="text"
        // value={email}
        placeholder="Email"
        value={email} name="email" hidden
        // onChange={(e) => setEmail(e.target.value)}
      />
      <input type="text" value={token} name="token" hidden/>
      <div>
      {/* <Button id="signin" type="submit">BTN</Button> */}
      </div>
      {/* <button id="create" type="submit" onClick={googleloginsuccess}>Create</button> */}
    </div>
    </form> 

    </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div> 
    

      </div>
    );
}

export default Login;
