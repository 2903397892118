import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Components from "./components/index";
import { DefaultLayout } from "./layouts/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import axios from "axios";

class App extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
       {  <Route
          path="/"
          children={(routeProps) =>
            routeProps.match.isExact ? <Redirect to="/accountslogin" /> : ""
          }
        /> }
        <Route path={"/accountslogin"} component={Components.Login} />
        <Route path={"/form"} component={MyLayout} />
    
        <Route exact path="/FormDetail/:formId" component={MyLayout} />
 
        
      </div>
    );
  }
}

class MyLayout extends React.Component {
  render() {
    return (
      <>
       
       {/* <Route path={"/form"} component={Components.GoogleForm} /> */}
      <Route path={"/Login"} component={Components.Login} />
      <Route path={"/googlesignin"} component={Components.GoogleSignIn} />
      
      <DefaultLayout>

        <Route
          exact
          path="/DiagnosisMaster/:patientId"
          render={(props) => <Components.DiagnosisMaster {...props} />}
        />
       
      </DefaultLayout>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

App.propTypes = {};

const AppWithProps = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppWithProps;
